import i18n from "i18next";
import appEnv from "services/app";

import baremetal from "assets/images/environments/baremetal.png";
import coxedge from "assets/images/environments/coxedge.png";
import azure from "assets/images/environments/azure.png";
import aks from "assets/images/environments/aks.png";
import aws from "assets/images/environments/aws.png";
import eks from "assets/images/environments/eks.png";
import google_cloud from "assets/images/environments/google_cloud.png";
import gke from "assets/images/environments/gke.png";
import vmware from "assets/images/environments/vmware.png";
import maas from "assets/images/environments/maas.png";
import openshift from "assets/images/environments/openshift.png";
import openstack from "assets/images/environments/openstack.png";
import tencent from "assets/images/environments/tencent.png";
import small_vm from "assets/images/environments/small_vm.png";
import small_aws from "assets/images/environments/small_aws.png";
import small_eks from "assets/images/environments/small_eks.png";
import small_azure from "assets/images/environments/small_azure.png";
import small_aks from "assets/images/environments/small_aks.png";
import small_google from "assets/images/environments/small_google.png";
import small_gke from "assets/images/environments/small_gke.png";
import small_maas from "assets/images/environments/small_maas.png";
import small_openshift from "assets/images/environments/small_openshift.png";
import small_openstack from "assets/images/environments/small_openstack.png";
import small_minio from "assets/images/environments/small_minio.png";
import small_baremetal from "assets/images/environments/small_baremetal.png";
import small_coxedge from "assets/images/environments/small_coxedge.png";
import small_tencent from "assets/images/environments/small_tencent.png";
import eksa from "assets/images/environments/eksa.svg";

import OS_ICON from "assets/icons/os_layer.svg";
import K8S_ICON from "assets/icons/k8s_layer.svg";
import CNI_ICON from "assets/icons/cni_layer.svg";
import CSI_ICON from "assets/icons/csi_layer.svg";
import LOAD_BALANCER_ICON from "assets/icons/load_balancer_layer.svg";
import SECURITY_ICON from "assets/icons/security_layer.svg";
import LOGGING_ICON from "assets/icons/logging_layer.svg";
import INGRESS_ICON from "assets/icons/ingress_layer.svg";
import MONITORING_ICON from "assets/icons/monitoring_layer.svg";
import AUTHENTICATION_ICON from "assets/icons/authentication_layer.svg";
import SYSTEM_APP_ICON from "assets/icons/system_app_layer.svg";
import SERVICE_MESH_ICON from "assets/icons/service_mesh_layer.svg";
import REGISTRY_ICON from "assets/icons/registry_layer.svg";
import HELM_CHART_ICON from "assets/icons/helm_chart_layer.svg";
import MANIFEST_ICON from "assets/icons/manifest.svg";
import DATABASE_ICON from "assets/icons/database.svg";
import CONTAINER_ICON from "assets/icons/container.svg";
import MESSAGE_SYSTEM from "assets/icons/message-system.svg";
import OBJECT_STORAGE from "assets/icons/object-storage.svg";

import k3s from "assets/icons/k3s.svg";
import cncf_k8s from "assets/icons/cncf-k8s.svg";

import {
  concreteWhite,
  darkOrange,
  dodgerBlue,
  green,
  orange,
  red,
  winterMist,
  blue,
} from "utils/constants/colors";
import flags from "services/flags";
import { FLAGS } from "utils/constants/flags";
import { createSelector } from "reselect";

import {
  faCode,
  faTrashAlt,
  faArrowsRotate,
  faCircleUp,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faSparkles } from "@fortawesome/pro-regular-svg-icons";

export const ADMIN_GUID = "ADMIN_VIEW";
export const MANAGED_TO_PURE_ENVIRONMENT = {
  eks: "aws",
  aks: "azure",
  gke: "gcp",
  tke: "tencent",
};
export const AWS_ENVIRONMENT = {
  logoPath: aws,
  name: "AWS IaaS",
  apiKey: "aws",
  cloudType: "public-clouds",
  smallLogoPath: small_aws,
  cloudAccountLabel: "AWS",
};

// It has been removed from palette
export const EDGE_ENVIRONMENT = {
  logoPath: baremetal,
  smallLogoPath: small_baremetal,
  name: "Edge (Beta)",
  apiKey: "edge",
  disabled: !flags.has(FLAGS.EDGE_BAREMETAL),
  comingSoon: !flags.has(FLAGS.EDGE_BAREMETAL),
  child: "libvirt",
  cloudType: "edge",
};

export const EDGE_NATIVE = {
  logoPath: baremetal,
  smallLogoPath: small_baremetal,
  name: "Edge Native",
  apiKey: "edge-native",
  cloudType: "edge",
};

export const LIBVIRT_ENVIRONMENT = {
  logoPath: baremetal,
  smallLogoPath: small_baremetal,
  name: "Edge Virtualized",
  apiKey: "libvirt",
  disabled: !flags.has(FLAGS.EDGE_BAREMETAL),
  comingSoon: !flags.has(FLAGS.EDGE_BAREMETAL),
  hideAsOption: true,
  cloudType: "edge",
};

export const COXEDGE_ENVIRONMENT = {
  logoPath: coxedge,
  smallLogoPath: small_coxedge,
  name: "Cox Edge Iaas",
  apiKey: "coxedge",
  disabled: !flags.has(FLAGS.COXEDGE),
  comingSoon: !flags.has(FLAGS.COXEDGE),
  cloudType: "public-clouds",
  cloudAccountLabel: "Cox Edge",
};

export const AZURE_ENVIRONMENT = {
  logoPath: azure,
  name: "Azure IaaS",
  apiKey: "azure",
  smallLogoPath: small_azure,
  disabled: !flags.has(FLAGS.AZURE),
  cloudType: "public-clouds",
  cloudAccountLabel: "Azure",
};

export const GOOGLE_CLOUD_ENVIRONMENT = {
  logoPath: google_cloud,
  name: "GCP IaaS",
  apiKey: "gcp",
  smallLogoPath: small_google,
  disabled: !flags.has(FLAGS.GCP),
  cloudType: "public-clouds",
  cloudAccountLabel: "Google Cloud",
};

export const VMWARE_ENVIRONMENT = {
  logoPath: vmware,
  name: "VMware",
  apiKey: "vsphere",
  smallLogoPath: small_vm,
  cloudType: "datacenter",
};

export const MAAS_ENVIRONMENT = {
  logoPath: maas,
  name: "MAAS",
  apiKey: "maas",
  smallLogoPath: small_maas,
  cloudType: "datacenter",
  cloudAccountLabel: "MAAS",
};

export const OPENSHIFT_ENVIRONMENT = {
  logoPath: openshift,
  name: "OpenShift",
  apiKey: "openshift",
  smallLogoPath: small_openshift,
  comingSoon: true,
  disabled: true,
  hideAsOption: true,
  cloudType: "public-clouds",
};

export const EKS_ENVIRONMENT = {
  logoPath: eks,
  name: "AWS EKS",
  apiKey: "eks",
  smallLogoPath: small_eks,
  isSupervised: true,
  cloudType: "public-clouds",
  cloudAccountLabel: "AWS",
};

export const EKSA_ENVIRONMENT = {
  logoPath: eksa,
  name: "EKS Anywhere",
  apiKey: "eksa",
  smallLogoPath: eksa,
  isSupervised: true,
  hideAsOption: true,
  cloudType: "public-clouds",
};

export const AKS_ENVIRONMENT = {
  logoPath: aks,
  name: "Azure AKS",
  apiKey: "aks",
  smallLogoPath: small_aks,
  isSupervised: true,
  disabled: !flags.has(FLAGS.AKS),
  comingSoon: !flags.has(FLAGS.AKS),
  cloudType: "public-clouds",
  cloudAccountLabel: "Azure",
};

export const GKE_ENVIRONMENT = {
  logoPath: gke,
  name: "GCP GKE",
  apiKey: "gke",
  smallLogoPath: small_gke,
  isSupervised: true,
  disabled: !flags.has(FLAGS.GKE),
  comingSoon: !flags.has(FLAGS.GKE),
  cloudType: "public-clouds",
  cloudAccountLabel: "Google Cloud",
};

export const OPENSTACK_ENVIRONMENT = {
  logoPath: openstack,
  name: "Openstack",
  apiKey: "openstack",
  smallLogoPath: small_openstack,
  disabled: !flags.has(FLAGS.OPENSTACK),
  comingSoon: !flags.has(FLAGS.OPENSTACK),
  cloudType: "datacenter",
  cloudAccountLabel: "Openstack",
};

export const MINIO_ENVIRONMENT = {
  name: "Minio",
  apiKey: "minio",
  smallLogoPath: small_minio,
  hidden: true,
};

export const TKE_ENVIRONMENT = {
  logoPath: tencent,
  smallLogoPath: small_tencent,
  name: "TKE",
  apiKey: "tke",
  isSupervised: true,
  cloudType: "public-clouds",
  cloudAccountLabel: "Tencent",
  // TODO: re-enable these
  // disabled: !flags.has(FLAGS.TKE),
  // comingSoon: !flags.has(FLAGS.TKE),
};

export const TENCENT_ENVIRONMENT = {
  logoPath: tencent,
  smallLogoPath: small_tencent,
  name: "Tencent",
  apiKey: "tencent",
  cloudType: "public-clouds",
  // TODO: re-enable these
  // disabled: !flags.has(FLAGS.TENCENT),
  // comingSoon: !flags.has(FLAGS.TENCENT),
};

export const INFRA_ENVIRONMENTS = [
  AWS_ENVIRONMENT,
  GOOGLE_CLOUD_ENVIRONMENT,
  TENCENT_ENVIRONMENT,
  MAAS_ENVIRONMENT,
  OPENSTACK_ENVIRONMENT,
  VMWARE_ENVIRONMENT,
  COXEDGE_ENVIRONMENT,
];

export const BAREMETAL = {
  logoPath: baremetal,
  smallLogoPath: small_baremetal,
  name: "Baremetal",
  apiKey: "baremetal",
};

let KNOWN_ENVIRONMENTS = {
  [AWS_ENVIRONMENT.apiKey]: AWS_ENVIRONMENT,
  [VMWARE_ENVIRONMENT.apiKey]: VMWARE_ENVIRONMENT,
  [AZURE_ENVIRONMENT.apiKey]: AZURE_ENVIRONMENT,
  [GOOGLE_CLOUD_ENVIRONMENT.apiKey]: GOOGLE_CLOUD_ENVIRONMENT,
  [OPENSTACK_ENVIRONMENT.apiKey]: OPENSTACK_ENVIRONMENT,
  [OPENSHIFT_ENVIRONMENT.apiKey]: OPENSHIFT_ENVIRONMENT,
  [MAAS_ENVIRONMENT.apiKey]: MAAS_ENVIRONMENT,
  [EDGE_NATIVE.apiKey]: EDGE_NATIVE,
  [LIBVIRT_ENVIRONMENT.apiKey]: LIBVIRT_ENVIRONMENT,
  [EKS_ENVIRONMENT.apiKey]: EKS_ENVIRONMENT,
  [EKSA_ENVIRONMENT.apiKey]: EKSA_ENVIRONMENT,
  [AKS_ENVIRONMENT.apiKey]: AKS_ENVIRONMENT,
  [GKE_ENVIRONMENT.apiKey]: GKE_ENVIRONMENT,
  [TKE_ENVIRONMENT.apiKey]: TKE_ENVIRONMENT,
};

export const getEnvironments = createSelector(
  (state) => state?.auth?.currentOrganization?.securityMode,
  () => appEnv?.cloudTypes,
  (isFipsMode, cloudTypes) => {
    let environments = cloudTypes
      .filter((env) => !!KNOWN_ENVIRONMENTS[env.name] || !!env.isCustom)
      .map((env) => ({
        ...env,
        ...(KNOWN_ENVIRONMENTS[env.name] || {}),
        apiKey: env.name,
      }));

    if (isFipsMode === "fips") {
      environments = environments.filter((env) => env.isVertex || env.isCustom);
    }

    return environments.sort((a, b) => {
      if (a.comingSoon) {
        return 1;
      }
      if (b.comingSoon) {
        return -1;
      }
      if (a.isSupervised && b.isSupervised) {
        return a.name.localeCompare(b.name);
      }
      if (a.isSupervised) {
        return 1;
      }
      if (b.isSupervised) {
        return -1;
      }
      return a.name.localeCompare(b.name);
    });
  }
);

export const LOCATION_PROVIDERS = [MINIO_ENVIRONMENT];
export const EXTRA_KNOWN_ENVIRONMENTS = [
  OPENSHIFT_ENVIRONMENT,
  EKSA_ENVIRONMENT,
];

export const CSI_LAYER = {
  type: "csi",
  title: () => i18n.t("Storage"),
  color: "#B2BAD9",
  icon: CSI_ICON,
};
const CNI_LAYER = {
  type: "cni",
  title: () => i18n.t("Network"),
  color: "#8B98C6",
  icon: CNI_ICON,
};
export const KUBERNETES_LAYER = {
  type: "k8s",
  title: () => i18n.t("Kubernetes"),
  color: "#6475B3",
  icon: K8S_ICON,
};
const OS_LAYER = {
  type: "os",
  title: () => i18n.t("OS"),
  color: "#3E539F",
  icon: OS_ICON,
};

const LOAD_BALANCER_LAYER = {
  type: "load balancer",
  title: () => i18n.t("Load balancer"),
  color: "#D6D490",
  isDeletable: true,
  icon: LOAD_BALANCER_ICON,
};
export const INGRESS_LAYER = {
  type: "ingress",
  title: () => i18n.t("Ingress"),
  color: "#D6D490",
  isDeletable: true,
  icon: INGRESS_ICON,
};
const LOGGING_LAYER = {
  type: "logging",
  title: () => i18n.t("Logging"),
  color: "#98D6BE",
  isDeletable: true,
  icon: LOGGING_ICON,
};
const MONITORING_LAYER = {
  type: "monitoring",
  title: () => i18n.t("Monitoring"),
  color: "#98D6BE",
  isDeletable: true,
  icon: MONITORING_ICON,
};
const SECURITY_LAYER = {
  type: "security",
  title: () => i18n.t("Security"),
  color: "#B398D9",
  isDeletable: true,
  icon: SECURITY_ICON,
};
const AUTHENTICATION_LAYER = {
  type: "authentication",
  title: () => i18n.t("Authentication"),
  color: "#B398D9",
  isDeletable: true,
  icon: AUTHENTICATION_ICON,
};
const SYSTEM_LAYER = {
  type: "system app",
  title: () => i18n.t("System App"),
  color: "#FEBDB4",
  isDeletable: true,
  icon: SYSTEM_APP_ICON,
};

const APP_SERVICES = {
  type: "app services",
  title: () => i18n.t("App services"),
  color: darkOrange,
  isDeletable: true,
  icon: SYSTEM_APP_ICON,
};

const SERVICE_MESH_LAYER = {
  type: "servicemesh",
  title: () => i18n.t("Service Mesh"),
  color: "#A2E6FB",
  isDeletable: true,
  icon: SERVICE_MESH_ICON,
};

const REGISTRY_LAYER = {
  type: "registry",
  title: () => i18n.t("Registry"),
  color: "#A2E6FB",
  isDeletable: true,
  icon: REGISTRY_ICON,
};

export const HELM_CHART_LAYER = {
  type: "helmChart",
  title: () => i18n.t("Helm Chart"),
  color: "#D6AFA7",
  isDeletable: true,
  icon: HELM_CHART_ICON,
};

export const ZARF_PACK_LAYER = {
  type: "zarf",
  title: () => i18n.t("Zarf package"),
  color: "#D6AFA7",
  isDeletable: true,
  icon: REGISTRY_ICON,
};

export const OCI_CHART_LAYER = {
  type: "ociHelmChart",
  title: () => i18n.t("Helm Chart"),
  color: "#D6AFA7",
  isDeletable: true,
  icon: HELM_CHART_ICON,
};

const MANIFEST_LAYER = {
  type: "manifest",
  title: () => i18n.t("Manifest"),
  color: "#EB9CAC",
  isDeletable: true,
  icon: MANIFEST_ICON,
};

export const VM_MANIFEST_LAYER = {
  type: "manifest",
  title: () => i18n.t("VM"),
  color: "#EB9CAC",
  isDeletable: true,
  icon: MANIFEST_ICON,
};

export const CONTAINER_MANIFEST_LAYER = {
  type: "manifest",
  title: () => i18n.t("Container"),
  color: darkOrange,
  isDeletable: true,
  icon: MANIFEST_ICON,
};

const SERVERLESS_LAYER = {
  type: "serverless",
  title: () => i18n.t("Serverless"),
  color: dodgerBlue,
  isDeletable: true,
  icon: SERVICE_MESH_ICON,
};

export const HELM_LAYER = {
  type: "helm",
  title: () => i18n.t("Helm"),
  color: "#2A8D88",
  isDeletable: true,
  icon: HELM_CHART_ICON,
};

export const DB_LAYER = {
  type: "operator-instance",
  title: () => i18n.t("Database"),
  color: "#0E8A8A",
  isDeletable: true,
  icon: DATABASE_ICON,
};

export const CONTAINER_LAYER = {
  type: "container",
  title: () => i18n.t("Container"),
  color: "#0E8A8A",
  isDeletable: true,
  icon: CONTAINER_ICON,
};

export const MESSAGE_SYSTEM_LAYER = {
  type: "message-broker",
  title: () => i18n.t("Messaging System"),
  color: "#0E8A8A",
  isDeletable: true,
  icon: MESSAGE_SYSTEM,
};

export const OBJECT_STORAGE_LAYER = {
  type: "object-storage",
  title: () => i18n.t("Object Storage"),
  color: "#0E8A8A",
  isDeletable: true,
  icon: OBJECT_STORAGE,
};

export const LAYER_TYPES = [
  OS_LAYER,
  CSI_LAYER,
  CNI_LAYER,
  KUBERNETES_LAYER,
  LOAD_BALANCER_LAYER,
  INGRESS_LAYER,
  LOGGING_LAYER,
  MONITORING_LAYER,
  SECURITY_LAYER,
  AUTHENTICATION_LAYER,
  SERVICE_MESH_LAYER,
  SYSTEM_LAYER,
  APP_SERVICES,
  REGISTRY_LAYER,
  HELM_CHART_LAYER,
  ZARF_PACK_LAYER,
  OCI_CHART_LAYER,
  MANIFEST_LAYER,
  SERVERLESS_LAYER,
  HELM_LAYER,
  DB_LAYER,
  CONTAINER_LAYER,
  MESSAGE_SYSTEM_LAYER,
  OBJECT_STORAGE_LAYER,
];

export const SPECTRO_TAG = "spectro__tag";

export const ENV_COLORS = {
  aws: "#ff9900",
  vsphere: "#717075",
};

export const PASSWORD_UPDATE_POLICY = [
  {
    label: "3 months",
    value: 90,
  },
  { label: "6 months", value: 183 },
  { label: "Custom", value: "Custom" },
];

export const UPDATE_STRATEGIES = [
  {
    label: "Expand First",
    value: "RollingUpdateScaleOut",
  },
  {
    label: "Contract First",
    value: "RollingUpdateScaleIn",
  },
];

export const CLUSTER_PROFILE_TYPES = [
  {
    label: "Full",
    value: "cluster",
    "data-qa-value": "full",
  },
  {
    label: "Infrastructure",
    value: "infra",
    "data-qa-value": "infra",
  },
  {
    label: "Add-on",
    value: "add-on",
    "data-qa-value": "addon",
  },
];

export const FIPS_TYPES = [
  {
    label: "Full",
    value: "full",
  },
  {
    label: "None",
    value: "none",
  },
  {
    label: "Partial",
    value: "partial",
  },
  {
    label: "Unknown",
    value: "unknown",
  },
];

export const CLUSTER_PROFILE_CONTEXT_OPTIONS = [
  {
    label: "System",
    value: "system",
    "data-qa-value": "system-context",
  },
  {
    label: "Tenant",
    value: "tenant",
    "data-qa-value": "tenant-context",
  },
  {
    label: "Project",
    value: "project",
    "data-qa-value": "project-context",
  },
];

export const APPLIANCES_STATES_MAP = {
  ready: {
    label: "Ready",
    value: "ready",
    "data-qa-value": "ready",
    info: "Edge hosts that are paired (registered) and are not already allocated to a cluster. They may be added to new or existing clusters.",
  },
  inUse: {
    label: "In-Use",
    value: "in-use",
    "data-qa-value": "in-use",
    info: "These edge hosts are part of a cluster.",
  },
  unpaired: {
    label: "Unpaired",
    value: "unpaired",
    "data-qa-value": "unpaired",
    info: "Edge hosts that have been added manually in Palette but have not yet registered. Unpaired edge hosts may be allocated to clusters - when they register to Palette, they will automatically become part of either a new or existing cluster.",
  },
};

export const APPLIANCE_STATES = [
  APPLIANCES_STATES_MAP.ready,
  APPLIANCES_STATES_MAP.inUse,
  APPLIANCES_STATES_MAP.unpaired,
];

export const ARCHITECTURE_TYPES = [
  {
    label: "AMD64",
    value: "amd64",
    "data-qa-value": "amd64",
  },
  {
    label: "ARM64",
    value: "arm64",
    "data-qa-value": "arm64",
  },
];

export const SPECTRO_FOLDER_PREFIX = "spc-";

export const ADDON_LAYERS = [
  LOAD_BALANCER_LAYER,
  INGRESS_LAYER,
  LOGGING_LAYER,
  MONITORING_LAYER,
  SECURITY_LAYER,
  AUTHENTICATION_LAYER,
  SERVICE_MESH_LAYER,
  SYSTEM_LAYER,
  // SERVERLESS_LAYER,
  APP_SERVICES,
  REGISTRY_LAYER,
  ZARF_PACK_LAYER,
  HELM_CHART_LAYER,
];

export const BEGINNING_OF_TIME = "0001-01-01T00:00:00.000Z";

export const DEFAULT_SCHEDULE_OPTIONS = [
  {
    label: () => i18n.t("Every week on Sunday at midnight"),
    value: "0 0 * * SUN",
  },
  {
    label: () => i18n.t("Every two weeks at midnight"),
    value: "0 0 1,15 * *",
  },
  {
    label: () => i18n.t("Every month on the 1st at midnight"),
    value: "0 0 1 * *",
  },
  {
    label: () => i18n.t("Every two months on the 1st at midnight"),
    value: "0 0 1 */2 *",
  },
  {
    label: () => i18n.t("Never"),
    value: "never",
  },
  {
    label: () => i18n.t("Custom"),
    value: "custom",
  },
];

export const DOWNLOAD_LOGS_OPTIONS = [
  {
    label: () => i18n.t("Kube-system logs"),
    value: "kubeSystem",
  },
  {
    label: () => i18n.t("Spectro Cloud logs"),
    value: "spectroCloud",
  },
  { label: () => i18n.t("Nodes logs"), value: "nodes" },
];

export const EXPIRY_PERIODS = [
  {
    label: () => i18n.t("1 month"),
    value: 730,
  },
  {
    label: () => i18n.t("2 months"),
    value: 1460,
  },
  { label: () => i18n.t("3 months"), value: 2190 },
  { label: () => i18n.t("Custom"), value: "custom" },
];

export const FOUR_MONTHS_IN_HOURS = 2920;
export const GENERAL_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

export const WORKLOADS_TIME_PERIODS = {
  "6 hours": {
    period: 15,
    length: 4 * 6,
  },
  "12 hours": {
    period: 15,
    length: 4 * 12,
  },
  "24 hours": {
    period: 30,
    length: 2 * 24,
  },
  "7 days": {
    period: 1440,
    length: 7,
  },
};

export const CONSUMPTION_FILTERS = [
  {
    key: "cpu",
    label: () => i18n.t("CPU"),
  },
  {
    key: "memory",
    label: () => i18n.t("Memory"),
  },
];

export const WORKLOADS_WITH_METRICS = [
  "pods",
  "deployments",
  "daemonsets",
  "statefulsets",
];

export const BAREMETAL_ENVS = ["edge", "libvirt", "edge-native"];

export const ENDPOINT_ACCESS_OPTIONS = [
  {
    label: () => i18n.t("Private & Public"),
    value: "private-public",
    description: () =>
      i18n.t(
        "The cluster endpoint is accessible from outside of your VPC. Worker node traffic to the endpoint will stay within your VPC."
      ),
  },
  {
    label: () => i18n.t("Private"),
    value: "private",
    description: () =>
      i18n.t(
        "The cluster endpoint is only accessible through your VPC. Worker node traffic to the endpoint will stay within your VPC."
      ),
  },
  {
    label: () => i18n.t("Public"),
    value: "public",
    description: () =>
      i18n.t(
        "The cluster endpoint is accessible from outside of your VPC. Worker node traffic will leave your VPC to connect to the endpoint."
      ),
  },
];

export const STATIC_PLACEMENT_DESCRIPTION = () =>
  i18n.t(
    `Enable “Static Placement” to deploy resources into an existing VPC and its subnets. If left unchecked, a new VPC is created with both public and private subnets. Palette will deploy resources in the new VPC.`
  );

export const CLUSTER_CREATION_STEPS_SUBTITLES = {
  "basic-info": () => i18n.t("Add cluster information"),
  "infra-profile": () => i18n.t("Cluster profile"),
  parameters: () => i18n.t("Configure cluster profiles"),
  "cluster-config": () => i18n.t("Cluster configuration"),
  "nodes-config": () => i18n.t("Node pools configuration"),
  policies: () => i18n.t("Optional cluster settings"),
  review: () => i18n.t("Review"),
};

export const WORKSPACE_CREATION_STEPS_SUBTITLES = {
  "basic-info": () => i18n.t("Basic Information"),
  clusters: () => i18n.t("Clusters"),
  namespaces: () => i18n.t("Namespaces"),
  policies: () => i18n.t("Settings"),
  review: () => i18n.t("Review"),
};

export const LOCATION_CONTEXT_TAGS = {
  tenant: { label: () => i18n.t("ADMIN"), color: winterMist },
  project: { label: () => i18n.t("PROJECT"), color: "rgba(87, 202, 129, 0.2)" },
};

export const VIRTUAL_CLUSTER_CREATION_STEPS_SUBTITLES = {
  "basic-info": () => i18n.t("Basic information"),
  profile: () => i18n.t("Cluster Profile"),
  review: () => i18n.t("Review"),
};

export const VIRTUAL_CLUSTER_CREATION_STEPS = [
  {
    title: () => i18n.t("Basic information"),
    id: "basic-info",
  },
  {
    title: () => i18n.t("Add-On Profiles"),
    id: "profile",
    description: "(optional)",
  },
  {
    title: () => i18n.t("Review"),
    id: "review",
  },
];

export const CLUSTER_ENDPOINT_TYPE_OPTIONS = [
  {
    label: () => i18n.t("Load Balancer"),
    value: "LoadBalancer",
  },
  {
    label: () => i18n.t("Ingress"),
    value: "Ingress",
  },
];

export const QUOTA_USAGE_BAR_LEGEND_ITEMS = [
  { label: () => i18n.t("Used"), color: "#9fa9b7" },
  { label: () => i18n.t("Allocated"), color: "#80dbb2" },
  { label: () => i18n.t("Available"), color: "#e8ebee" },
];

export const MAP_FILTER_LABEL_MAPPING = {
  total: {
    label: () => i18n.t("All Clusters Displayed "),
    color: "#F7F8F9",
    textColor: "#3E4856",
  },
  failed: {
    label: () => i18n.t("Failed"),
    color: "#E61E50",
    textColor: "#fff",
  },
  degraded: {
    label: () => i18n.t("Degraded"),
    color: "#FFF4CF",
    textColor: "#3E4856",
  },
  pending: {
    label: () => i18n.t("Updates Pending"),
    color: "#528CDD",
    textColor: "#fff",
  },
  healthy: {
    label: () => i18n.t("Healthy"),
    color: "#6DDCB4",
    textColor: "#3E4856",
  },
  disabled: {
    label: () => i18n.t("Disabled"),
    color: "#818ea1",
    textColor: "#fff",
  },
};

export const REGISTRY_SCOPE_SPECIFICITY = {
  system: 2,
  tenant: 1,
};

export const SYFT_SCAN_FORMAT_OPTIONS = [
  { label: "cyclonedx-json", value: "cyclonedx-json" },
  { label: "spdx-json", value: "spdx-json" },
  { label: "syft-json", value: "syft-json" },
];

export const SYFT_SCAN_SCOPE_OPTIONS = [
  { label: "cluster", value: "cluster" },
  { label: "namespace", value: "namespace" },
  { label: "label-selector", value: "label-selector" },
  { label: "pod", value: "pod" },
];

export const CLUSTER_GROUP_SCOPE_TAGS = {
  system: { label: "SYSTEM", color: concreteWhite },
  tenant: { label: "ORG", color: "#FFF4CF" },
  project: { label: "PROJECT", color: "#D3F7ED" },
};

export const EVENT_STATUSES = [
  {
    type: "Normal",
    label: () => i18n.t("Normal"),
  },
  {
    type: "Warning",
    label: () => i18n.t("Warning"),
  },
  {
    type: "Error",
    label: () => i18n.t("Error"),
  },
];

export const EVENT_TIMELINE_OPTIONS = [
  {
    label: () => i18n.t("All time"),
    value: "all-time",
  },
  {
    label: () => i18n.t("Last 5 minutes"),
    value: "5 minutes",
  },
  {
    label: () => i18n.t("Last hour"),
    value: "1 hours",
  },
  {
    label: () => i18n.t("Last 6 hours"),
    value: "6 hours",
  },
  {
    label: () => i18n.t("Last 12 hours"),
    value: "12 hours",
  },
  {
    label: () => i18n.t("Last 24 hours"),
    value: "24 hours",
  },
  {
    label: () => i18n.t("Last week"),
    value: "1 week",
  },
  {
    label: () => i18n.t("Last month"),
    value: "1 month",
  },
];

export const TENANT_LEVEL_CLUSTERS_PROJECT = {
  name: "Tenant scoped clusters",
  uid: "tenant_level_clusters_project_id",
};

export const VIRTUAL_CLUSTER_KUBERNETES_DISTRO = [
  { label: () => i18n.t("K3s (default)"), value: "k3s", icon: k3s },
  { label: () => i18n.t("CNCF K8s (BETA)"), value: "cncf_k8s", icon: cncf_k8s },
];

export const DEFAULT_EDGE_NATIVE_INTERNAL_CIDR = "100.64.192.0/23";

export const PACK_DIFF_DETAILS_MAPPING = {
  added: {
    icon: faSparkles,
    label: () => i18n.t("Pack added"),
  },
  values: {
    icon: faCode,
    label: () => i18n.t("Pack values update"),
    color: orange,
  },
  removed: {
    icon: faTrashAlt,
    label: () => i18n.t("Pack removed"),
    color: red,
  },
  version: {
    icon: faArrowsRotate,
    label: () => i18n.t("Pack version update"),
  },
  update: {
    icon: faCircleUp,
    label: () => i18n.t("Pack update"),
    color: green,
  },
  info: {
    icon: faInfoCircle,
    label: () => i18n.t("Update info"),
    color: blue,
  },
};

export const STACKING_CONTEXT_1 = 1;
export const STACKING_CONTEXT_2 = 2;

export const ERROR_SUMMARY_MESSAGES = {
  missingValues: (packType) =>
    i18n.t("{{packType}} values are missing", { packType }),
  invalidValues: (packType) =>
    i18n.t("{{packType}} values are not a valid YAML format", { packType }),
  invalidProperties: (packType) =>
    i18n.t("Some {{packType}} properties are missing or invalid", { packType }),
};
